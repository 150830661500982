<template>
  <div>
    <input ref="map" type="hidden">
    <b-field>
      <b-autocomplete
        field="address"
        :data="data"
        :value="adress"
        :loading="isFetching"
        :placeholder="placeholder"
        @typing="fetchAddresses"
        @select="selectAddress"
      >
        <template slot-scope="props">
          <div class="media">
            <div class="media-content">
              <div class="is-flex is-align-items-center">
                <svg
                  v-if="props.option.id"
                  xmlns="http://www.w3.org/2000/svg"
                  viewBox="0 0 20 20"
                  fill="currentColor"
                  :class="props.option.user_id ? 'has-text-link' : 'has-text-danger'"
                  style="height:15px; width:15px;"
                >
                  <path d="M9.049 2.927c.3-.921 1.603-.921 1.902 0l1.07 3.292a1 1 0 00.95.69h3.462c.969 0 1.371 1.24.588 1.81l-2.8 2.034a1 1 0 00-.364 1.118l1.07 3.292c.3.921-.755 1.688-1.54 1.118l-2.8-2.034a1 1 0 00-1.175 0l-2.8 2.034c-.784.57-1.838-.197-1.539-1.118l1.07-3.292a1 1 0 00-.364-1.118L2.98 8.72c-.783-.57-.38-1.81.588-1.81h3.461a1 1 0 00.951-.69l1.07-3.292z" />
                </svg>
                <svg
                  v-else
                  xmlns="http://www.w3.org/2000/svg"
                  viewBox="0 0 20 20"
                  fill="currentColor"
                  class="has-text-grey"
                  style="height:15px; width:15px;"
                >
                  <path fill-rule="evenodd" d="M5.05 4.05a7 7 0 119.9 9.9L10 18.9l-4.95-4.95a7 7 0 010-9.9zM10 11a2 2 0 100-4 2 2 0 000 4z" clip-rule="evenodd" />
                </svg>
                <b class="pl-1">{{ props.option.name }}</b>
              </div>
              <small>
                {{ props.option.address }}
              </small>
            </div>
          </div>
        </template>
      </b-autocomplete>
    </b-field>
    <!--<div class="field">
      <div class="control has-icons-left is-clearfix">
        <input
          ref="input"
          :value="adress"
          :placeholder="placeholder"
          type="text"
          name="name"
          class="input"
          :disabled="disabled"
        >
        <span class="icon is-left">
          <i class="mdi mdi-map-marker mdi-24px" />
        </span>
      </div>
    </div>-->
  </div>
</template>

<script>
import debounce from 'lodash/debounce'

export default {
  name: 'GMapPlacesInput',
  props: {
    place: {
      type: Object,
      default: null
    },
    placeholder: {
      type: String,
      default: null
    },
    adress: {
      type: String,
      default: null
    },
    disabled: {
      type: Boolean,
      default: false
    }
  },
  data () {
    return {
      data: [],
      isFetching: false,
    }
  },
  mounted () {
    if (typeof (window.google.maps.places.Autocomplete) !== 'function') {
      throw new Error('google.maps.places.Autocomplete is undefined. Did you add \'places\' to libraries when loading Google Maps?')
    }

    /* this.$autocomplete = new window.google.maps.places.Autocomplete(this.$refs.input);
    // https://developers.google.com/maps/documentation/javascript/reference/places-service#PlaceResult.address_components
    this.$autocomplete.setFields(['formatted_address', 'geometry', 'name']);

    this.$autocomplete.addListener('place_changed', () => {
      let gPlace = this.$autocomplete.getPlace();

      if (gPlace.geometry.location) {
        let latLng = `${gPlace.geometry.location.lat()},${gPlace.geometry.location.lng()}`
        gPlace.latlng = latLng;
        this.$emit('update:place', gPlace);
      }
    }); */
  },
  methods: {
    fetchAddresses: debounce(function (name) {
      if (!name.length) {
        this.data = []
        return
      }

      this.isFetching = true

      const params = {
        name,
        per_page: 10,
        'with-own-records': 1,
      }

      this.$http.get('/addresses', { params }).then(({ data }) => {
        this.data = data.data

        const service = new window.google.maps.places.AutocompleteService()

        service.getQueryPredictions({ input: name }, (predictions, status) => {
          if (status !== window.google.maps.places.PlacesServiceStatus.OK || !predictions) {
            return
          }

          predictions = predictions.filter((prediction) => prediction.place_id)

          predictions.forEach((prediction) => {
            this.data.push({ name, address: prediction.description, place_id: prediction.place_id })
          })

          this.isFetching = false
        })

      }).catch(() => {
        this.data = []
      })
    }, 500),
    selectAddress (option) {
      if (!option) {
        return
      }

      if (option.id && option.location) {
        option.formatted_address = `${option.name} - ${option.address}`
        option.latlng = option.location
        return this.$emit('update:place', option)
      }

      const service = new window.google.maps.places.PlacesService(new window.google.maps.Map(this.$refs.map))

      service.getDetails({ placeId: option.place_id, fields: ['name', 'formatted_address', 'geometry'] }, (place, status) => {
        if (status === window.google.maps.places.PlacesServiceStatus.OK && place && place.geometry && place.geometry.location) {
          option.address = place.formatted_address
          option.formatted_address = place.formatted_address
          option.latlng = `${place.geometry.location.lat()},${place.geometry.location.lng()}`
          this.$emit('update:place', option)
        }
      })
    },
  },
}
</script>
